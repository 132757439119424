<template>
	<div>
		<h1>Sent Invites</h1>
		<v-alert v-model="invalidEmail" class="mx-4" dismissible type="error">
			{{ inviteAlertMessage }}
		</v-alert>

		<v-list v-if="!loading">
			<v-list-item
				v-for="item in invites"
				:key="item.id"
				@click="showItemDetes(item)"
			>
				<v-list-item-content>
					{{ item.email }}
					<v-row>
						<v-col>
							<v-chip
								:color="item.accepted == '0' ? 'primary' : 'green'"
								small
								class="mx-1"
							>
								{{ inviteStatus(item) }}
							</v-chip>

							<v-chip color="primary" small class="mx-1">
								Invited by: {{ item.InvitedBY }}
							</v-chip>
						</v-col>
					</v-row>
				</v-list-item-content>
				<v-list-item-action>
					<v-icon>
						mdi-chevron-right
					</v-icon>
				</v-list-item-action>
			</v-list-item>
		</v-list>
		<div>
			<YesNo
				v-model="showDetes"
				:Header="itemDetes.Title"
				:Display="showDetes"
				YesBtn="Close"
				YesColor="primary"
				NoBtn="Delete Invite?"
				@Result="deleteInvite"
			>
				<v-row>
					<v-col cols="4">
						<v-icon>
							mdi-email
						</v-icon>
						Invitee
					</v-col>
					<v-col>
						<a :href="`mailto:${itemDetes.email}`"> {{ itemDetes.email }}</a>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="4">
						<v-icon>
							mdi-account
						</v-icon>
						Invited By
					</v-col>
					<v-col>
						{{ itemDetes.InvitedBY }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="4">
						<v-icon>
							mdi-check
						</v-icon>
						Invite Status
					</v-col>
					<v-col>
						{{
							itemDetes.accepted == 0 ? "Invite Pending" : "Invite Accepted!"
						}}
					</v-col>
				</v-row>
			</YesNo>
		</div>

		<v-btn
			absolute
			fab
			color="primary"
			right
			class="ma-16"
			@click="createInvite"
		>
			<v-icon>
				mdi-plus
			</v-icon>
		</v-btn>

		<div>
			<YesNo
				v-model="showCreateInvite"
				Header="Send Invite"
				@Result="sendInvite"
				YesBtn="Send"
				NoBtn="Cancel"
			>
				<v-text-field
					ref="form"
					label="Email Address"
					v-model="inviteEmail"
					filled
					dense
					clearable
					prepend-icon="mdi-account"
					:rules="rules"
					@keyup.enter="sendInvite"
				/>
			</YesNo>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import YesNo from "@/components/ModalMessages/YesNo.vue";
// import Alert from '@/components/ModalMessages/Alert.vue';
// import ScreenDialog from '@/components/ModalMessages/ScreenDialog.vue';
export default {
	components: { YesNo },
	data() {
		return {
			invites: {
				id: null,
				email: null,
				accepted: false,
				title: null,
				invitedBy: null,
			},
			loading: true,
			showDetes: false,
			itemDetes: {
				id: null,
				title: null,
				email: null,
				accepted: null,
				invitedBy: null,
			},
			showCreateInvite: false,
			inviteID: null,
			inviteEmail: null,
			invalidEmail: false,
			inviteAlertMessage: null,

			rules: [
				(value) => !!value || "Required.",
				(value) => (value || "").length <= 64 || "Max 64 characters",
				(value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || "Invalid e-mail.";
				},
			],
		};
	},
	computed: {
		...mapState(["Organization"]),
	},
	methods: {
		inviteStatus(item) {
			return item.accepted == 0 ? "Invite Pending" : "Invite Accepted!";
		},

		showItemDetes(item) {
			this.itemDetes = item;
			this.showDetes = true;
			// let invExists = this.invites.some(inv => inv.email === this.itemDetes.email)
			// console.log(invExists)
		},

		createInvite() {
			this.inviteEmail = null;
			this.showCreateInvite = true;
		},

		async deleteInvite(res) {
			if (res) return;

			try {
				await axios.delete(
					`https://api.ticketvm.com/organization/${this.Organization.id}/invites/deactivate/` +
						this.itemDetes.id
				);
			} catch (err) {
				this.inviteAlertMessage = "There was an issue deactivating the invite";
				this.invalidEmail = true;
				console.log(err);
				if (err.response) console.log(err.response);
			}

			this.invites = this.invites.filter(
				(invite) => invite.id !== this.itemDetes.id
			);
		},

		async sendInvite(res) {
			// console.log(this.inviteEmail)
			if (!res) return;
			if (this.invites.some((inv) => inv.email === this.inviteEmail)) {
				//alert user
				this.showCreateInvite = false;
				this.inviteAlertMessage = "This person has already been invited";
				this.invalidEmail = true;
				return;
			}

			if (!this.$refs.form.validate()) {
				this.inviteAlertMessage = "You must enter a valid email address";
				this.invalidEmail = true;
				this.showCreateInvite = false;
				return;
			}
			try {
				await axios.post(
					`https://api.ticketvm.com/organization/${this.Organization.id}/invites`,
					{
						email: this.inviteEmail,
					}
				);
			} catch (err) {
				this.inviteAlertMessage = "There was an issue with your invite";
				this.invalidEmail = true;
				console.log(err);
				if (err.response) console.log(err.response);
			}

			this.showCreateInvite = false;
			this.load();
		},

		async load() {
			try {
				let res = await axios.get(
					`https://api.ticketvm.com/organization/${this.Organization.id}/invites`
				);
				this.invites = res.data.map((e) => e);
				this.loading = false;
			} catch (err) {
				console.log(err);
				if (err.response) console.log(err.response);
			}
		},
	},
	async mounted() {
		this.load();
	},
};
</script>

<style></style>
