<template>
	<div>
		<v-list v-if="!loading">
			<v-list-item v-for="item in users" :key="item.id" @click="openItem(item)">
				<v-list-item-content>
					{{ item.Name }}
					<v-row>
						<v-col cols="3">
							<v-chip
								:color="item.Role == 'admin' ? 'primary' : 'dark'"
								small
								dark
							>
								{{ item.Role | capitalize }}
							</v-chip>
						</v-col>
					</v-row>
				</v-list-item-content>
				<v-list-item-action>
					<v-icon>
						mdi-chevron-right
					</v-icon>
				</v-list-item-action>
			</v-list-item>
		</v-list>
		<v-skeleton-loader type="list-item-two-line@5" :loading="true" v-else />

		<Alert Header="Ooooohhhhh Noooooo" v-model="showError">
			Dang it! We encountered an error. Please try again or contact support.
		</Alert>
		<div v-if="showAlert">
			<YesNo
				v-model="showAlert"
				@Result="updateUser"
				:Header="user.Name"
				:NoBtn="
					user.Role.toLowerCase() == 'admin' ? 'Make Agent' : 'Make Admin'
				"
				NoColor="dark"
				YesBtn="Close"
				YesColor="primary"
			>
				<v-row>
					<v-col align="end">
						<v-btn @click="deleteUser" fab x-small color="error">
							<v-icon>
								mdi-delete
							</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="4">Email</v-col>
					<v-col>
						<a :href="`mailto:${user.Email}`"> {{ user.Email }}</a>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="4">Phone</v-col>
					<v-col>
						<a :href="`tel:${user.Phone}`"> {{ user.Phone }}</a>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="4">Role</v-col>
					<v-col>{{ user.Role | capitalize }}</v-col>
				</v-row>
			</YesNo>
		</div>
		<YesNo
			v-model="showDeleteConfirm"
			@Result="deleteTheUser"
			:Header="`Delete ${user ? user.Name : ''}?`"
		>
			Are you sure you wish to delete this user? They will not be able to access
			this organization anymore.
		</YesNo>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Alert from "@/components/ModalMessages/Alert.vue";
import YesNo from "@/components/ModalMessages/YesNo.vue";
export default {
	components: { Alert, YesNo },
	data() {
		return {
			showError: false,
			showAlert: false,
			user: null,
			users: [],
			loading: true,
			showDeleteConfirm: false,
		};
	},
	computed: {
		...mapState(["Organization"]),
	},
	methods: {
		load() {
			this.users = this.$store.state.usersInOrg.filter((x) => x.id);
			this.loading = false;
		},
		openItem(item) {
			this.user = item;
			this.showAlert = true;
		},
		async updateUser(res) {
			this.loading = true;
			this.showAlert = false;
			if (!res) {
				let newRole =
					this.user.Role.toLowerCase() == "admin" ? "agent" : "admin";
				try {
					await axios.put(
						`https://api.ticketvm.com/organization/${this.Organization.id}/users/${this.user.id}/role/${newRole}`
					);
				} catch (err) {
					console.log(err);
					if (err.response) console.log(err.response);
				}
				await this.$store.dispatch("fetchUsers", this.Organization.id);
				this.load();
			}

			this.user = null;
			this.loading = false;
		},
		async deleteUser() {
			this.showDeleteConfirm = true;
		},
		async deleteTheUser(res) {
			if (!res) return;
			try {
				await axios.delete(
					`https://api.ticketvm.com/organization/${this.Organization.id}/users/${this.user.id}`
				);
			} catch (err) {
				console.log(err);
				if (err.response) console.log(err.response);
			}
			await this.$store.dispatch("fetchUsers", this.Organization.id);
			this.load();
		},
	},
	mounted() {
		this.load();
	},
	filters: {
		capitalize: function(value) {
			if (!value) return "";
			value = value.toString();
			return value.charAt(0).toUpperCase() + value.slice(1);
		},
	},
};
</script>

<style></style>
