<template>
	<div>
		<v-tabs v-model="tabs" fixed-tabs class="mb-3">
			<v-tabs-slider></v-tabs-slider>
			<v-tab>
				<v-icon>mdi-account-multiple</v-icon>
				Current Agents
			</v-tab>

			<v-tab>
				<v-icon>mdi-account-plus</v-icon>
				Agent Invites
			</v-tab>
		</v-tabs>
		<v-slide-x-transition>
			<Agents v-if="tabs == 0" />
		</v-slide-x-transition>
		<v-slide-x-transition>
			<Invites v-if="tabs == 1" />
		</v-slide-x-transition>
	</div>
</template>

<script>
import Agents from "./components/Agents/Agents.vue";
import Invites from "./components/Agents/Invites.vue";
export default {
	components: { Agents, Invites },
	data() {
		return {
			tabs: 0,
		};
	},
};
</script>

<style></style>
